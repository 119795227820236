<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar :variant="variant" size="1.8rem" class="mr-75 flex-shrink-0">
        <feather-icon :icon="icon" size="15" />
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div>
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="transLatedTitle"
          />
          <small v-if="text" class="d-inline-block" v-text="text" />
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="$emit('close-toast')"
        >
          <feather-icon v-if="!hideClose" icon="XIcon" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";

export default {
  components: {
    BAvatar,
  },
  computed: {
    transLatedTitle() {
      const locale = localStorage.getItem("lang") || "en";
      if (this.title === "Error") {
        switch (locale) {
          case "en":
            return "Error";
          case "fr":
            return "Erreur";
          case "de":
            return "Fehler";
          case "es":
            return "Error";
          case "ja":
            return "エラー";
          case "ar":
            return "خطأ";
          case "tu":
            return "Hata";
          case "ch":
            return "错误";
          default:
            return "Error";
        }
      }
      if (this.title === "Success") {
        switch (locale) {
          case "en":
            return "Success";
          case "fr":
            return "Succès";
          case "de":
            return "Erfolg";
          case "es":
            return "Éxito";
          case "ja":
            return "成功";
          case "ar":
            return "نجاح";
          case "tu":
            return "Başarı";
          case "ch":
            return "成功";
          default:
            return "Success";
        }
      }
      return this.title;
    },
  },
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: "",
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
