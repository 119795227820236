export default {
  namespaced: true,
  state: {
    tickets: {
      data: [],
      links: [],
    },
    count: 0,
  },
  getters: {
    tickets: (state) => state.tickets,
    count: (state) => state.count,
  },
  mutations: {
    tickets: (state, payload) => (state.tickets = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/customer-ticket", { params: queryParams })
        .then((res) => {
          commit("tickets", res?.data?.data ?? []);
          commit("count", res?.data?.data?.total ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post(`/ticket`, payload);
    },
    bulkEdit({}, payload) {
        return this.$apiService.post(`/ticket/bulk-edit`, payload);
    },
    show({}, id) {
        return this.$apiService.get(`/ticket/${id}`);
    },
    update({}, { id, data }) {
        return this.$apiService.put(`/ticket/${id}`, data);
    },
    destroy({}, id) {
        return this.$apiService.delete(`/ticket/${id}`);
    },
    // reOpenTicket({ id, status }) {
    //     return useNuxtApp().$apiService.get(
    //         `/ticket/re-open/${id}/${status}`
    //     );
    // },
  },
};
