export default {
  namespaced: true,
  state: {
    news: [],
    count: 0,
  },
  getters: {
    news: (state) => state.news,
    count: (state) => state.count,
  },
  mutations: {
    news: (state, payload) => (state.news = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/news", {
          params: queryParams,
        })
        .then((res) => {
          commit("news", res?.data?.data ?? []);
          commit("count", res?.data?.total ?? 0);
          return res;
        });
    },
  },
};
