export default {
  namespaced: true,
  state: {
    customers: [],
    count: 0,
    credits: 0,
  },
  getters: {
    customers: (state) => state.customers,
    count: (state) => state.count,
    credits: (state) => state.credits,
  },
  mutations: {
    customers: (state, payload) => (state.customers = payload),
    count: (state, payload) => (state.count = payload),
    credits: (state, payload) => (state.credits = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/companies", {
          params: queryParams,
        })
        .then((res) => {
          commit("customers", res?.data?.data ?? []);
          commit("count", res?.data?.total ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("/companies", payload);
    },
    show({}, id) {
      return this.$apiService.get(`/companies/${id}`);
    },
    update({}, { id, data }) {
      return this.$apiService.put(`/companies/${id}`, data);
    },
    destroy({}, id) {
      return this.$apiService.delete(`/companies/${id}`);
    },
    getCredits({ commit }) {
      return this.$apiService.get(`/get-credits`).then((res) => {
        commit("credits", res?.data?.credits ?? 0);
        return res;
      });
    },
    termsConditions({ commit }) {
      return this.$apiService.get(`/terms-conditions`).then((res) => {
        return res;
      });
    },
    saveTermsConditions({}, payload) {
      return this.$apiService.post("/save-terms-conditions", payload);
    },
  },
};
