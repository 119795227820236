import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import Prototypes from "./utils/Prototypes";

// Global Components
import "./global-components";
import SwalMixin from "@/mixins/swalMixin";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import AuthService from "./services/auth.service";
import TokenService from "./services/token.service";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import BootstrapVue from "bootstrap-vue";
import VueGoodTable from "vue-good-table";
import "vue-multiselect/dist/vue-multiselect.min.css";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";

if (AuthService.isLoggedIn() == true) {
  TokenService.broadcastToken();
}

//navigator.serviceWorker.register('/service-worker.js');

// BSV Plugin Registration
Vue.prototype.$formatter = Prototypes.formatter;
Vue.prototype.$hasRole = Prototypes.hasRole;
Vue.prototype.$can = Prototypes.can;
Vue.prototype.$dateFormatter = Prototypes.dateFormatter;
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);
Vue.use(VueGoodTable);
Vue.use(mavonEditor);
Vue.mixin(SwalMixin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
